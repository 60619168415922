import Highway from '@dogstudio/highway';
import { gsap, TweenMax, ScrollToPlugin } from "gsap";

export default class ToArticle extends Highway.Transition {

  out({ from, trigger, done }) {

    // Scroll top
    if(window.scroll.scroll.instance.scroll.y > 0) {
      window.scroll.scrollTo("top", 0, 0, [0.25, 0.00, 0.35, 1.00], false, function(){
        ToArticle.transitionOut(done);
      });
    } else {
      ToArticle.transitionOut(done);
    }

  }

  in({ from, to, trigger, done }) {

    // Remove the old content
    from.remove();

    // Remove the default image of the "normal" page
    to.querySelector('.section-mission-image-gradientbg').remove();
    to.querySelector('.section-mission-image-bg').remove();

    // Replace by the cloned image
    to.querySelector('.section-mission-image').appendChild(window.missionGradientBgClone);
    to.querySelector('.section-mission-image').appendChild(window.missionClone);

    // Set the sections to opacity 0
    gsap.set([
      to.querySelector('.section-mission-content'),
      to.querySelector('.section-content'),
      to.querySelector('.section-related')
    ], {opacity:0}, 0);

    // Make the transition and done;
    gsap.to([
      to.querySelector('.section-mission-content'),
      to.querySelector('.section-content'),
      to.querySelector('.section-related')
    ], {ease: "kuk", duration: .5, opacity: 1, onComplete:done});

  }

  static transitionOut(done) {

    // Hide the content sections
    gsap.to([
      document.querySelector('.section-mission-content'),
      document.querySelector('.section-about'),
      document.querySelector('.section-articles'),
      document.querySelector('.section-related'),
      document.querySelector('.section-mission-image-gradient')
    ], {
      ease: "kuk",
      duration: .5,
      opacity: 0
    });

    // Change clip path and opacity of clipped images

    var clippedPathLG = document.querySelector('.section-mission-image-clipped-lg').getAttribute('style');
    var clippedPathLG = clippedPathLG.replace('clip-path: ','');
    var clippedPathXL = document.querySelector('.section-mission-image-clipped-xl').getAttribute('style');
    var clippedPathXL = clippedPathXL.replace('clip-path: ','');
    const proxyLG = {clipPath: clippedPathLG};
    const proxyXL = {clipPath: clippedPathXL};

    gsap.to([
      document.querySelector('.section-mission-image-clipped-lg'),
      document.querySelector('.section-mission-image-clipped-xl')
    ], {
      ease: "kuk",
      duration: .8,
      opacity: 0,
      onStart:function() {

        gsap.to([proxyXL, proxyLG], 1, {
          clipPath: 'inset(0% 0% 0% 0% round 0px)',
          ease: "kuk",
          onUpdate:function() {
            //document.querySelector('.section-mission-image-clipped-lg').style.clipPath = proxyLG.clipPath;
            //document.querySelector('.section-mission-image-clipped-xl').style.clipPath = proxyXL.clipPath;
          }
        });

      },
      onComplete: function () {

        // Scale the basic image
        gsap.to(document.querySelector('.section-mission-image-bg'), {
          ease: "kuk",
          duration: 1,
          scale: 1.05
        });

        // Create the clone image
        window.missionGradientBgClone = document.querySelector('.section-mission-image-gradientbg');
        window.missionClone = document.querySelector('.section-mission-image-bg');

        done();
      }
    });

  }

}
