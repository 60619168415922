import { gsap } from "gsap";

export default class ScrollIndicator {

  constructor() {

    if(document.getElementById('scroll-indicator')) {
      var scrollAnimation = gsap.timeline()
      .set(document.getElementById('scroll-indicator-bar'), {scaleY:0})
      .to(document.getElementById('scroll-indicator-bar'), {
        scaleY:1,
        duration: 1,
        transformOrigin:"top",
      })
      .to(document.getElementById('scroll-indicator-bar'), {
        scaleY:0,
        duration: 1,
        transformOrigin:"bottom",
      });
      scrollAnimation.repeat(-1).repeatDelay(.5).play();

      window.scroll.on("scroll", (e) => {
        if(e.scroll.y > 500) {
          gsap.to(document.getElementById('scroll-indicator'), {opacity: 0, duration: 1});
        } else {
          gsap.to(document.getElementById('scroll-indicator'), {opacity: .5, duration: 1});
        }
      });

    }

  }

}
