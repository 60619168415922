import { gsap } from "gsap";
/***
 * Alpine functions
 */

// Search form
window.kukSearch = function(tags, ages) {

    return {
      q: "",
      count: 0,
      tags: tags,
      ages: ages,
      age:null,
      isLoading: false,
      url :'/search',
      reset(){
        this.q = '';
        this.getCount();
        for (let tag of Object.values(this.tags)) {
          tag.checked = false;
        }
        this.age = null;
      },

      getCount() {
        let tags = [];
        for (let tag of Object.values(this.tags)) {
          if(tag.checked){
            tags.push(tag.id);
          }
        }

        this.url = `/search?q=${this.q}&tags=${tags.toString()}&age=${this.age}`;
        this.isLoading = true;
        fetch(`/search/count?q=${this.q}&tags=${tags.toString()}&age=${this.age}`)
          .then(res => res.json())
          .then(data => {
            this.isLoading = false;
            this.count = data;
          });
      }
    };

}

// Hover btn
window.hoverEffect = function(e) {
  let  viewportOffset = e.target.getBoundingClientRect();
  let x = e.pageX - (viewportOffset.left + window.scrollX);
	let y = e.pageY - (viewportOffset.top + window.scrollY);
  e.target.style.setProperty('--x', `${ x }px`);
	e.target.style.setProperty('--y', `${ y }px`);
}
