import { gsap } from "gsap";

export default class TextAppear {

  static init(){

    gsap.timeline()
    .set(document.querySelectorAll('[data-router-view] .appear-text'), {y:50,opacity:0}, 0);

  }

  static observe(){

    if ("IntersectionObserver" in window) {

      IntersectionObserver.prototype.POLL_INTERVAL = 100; // Time in milliseconds

      // Appear block
      let lazyTextsObserver = new IntersectionObserver(function (entries, observer) {
        let texts = [];
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            if(!entry.target.dataset.played){
              entry.target.dataset.played = true;
              texts = texts.concat(entry.target);
            }
          }
        });
        TextAppear.appear(texts);
      });
      document.querySelectorAll('[data-router-view] .appear-text').forEach(function(text) {
        lazyTextsObserver.observe(text);
      });

    }

  }

  static appear(element = 0) {
    gsap.to( element, {
      opacity:1,
      y:0,
      ease: "expo.out",
      duration:1,
      delay:.25,
      stagger:{
        amount:.2,
        from:0
      }
    });
  }

}
