import Highway from '@dogstudio/highway';
import { gsap, ScrollToPlugin } from "gsap";

export default class Fade extends Highway.Transition {

  out({ from, trigger, done }) {

    gsap.timeline()
    .to(from, {
      ease: "kuk",
      duration: .5,
      opacity: 0,
      onComplete: function () {
        gsap.fromTo(document.querySelector('.js-loading-content'), {y: 0}, {ease: "kuk", y: -20, duration: .5});

        if(window.scroll.scroll.instance.scroll.y > 0) {
          window.scroll.scrollTo("top", 0, 0, [0.25, 0.00, 0.35, 1.00], false, function(){
            done();
          });
        } else {
          done();
        }
      }
    });

  }

  in({ from, to, trigger, done }) {
    from.remove();
    gsap.fromTo(to, {opacity: 0}, {ease: "kuk", duration: .5, opacity: 1, onComplete:done});
    gsap.fromTo(document.querySelector('.js-loading-content'), {ease: "kuk", y: 20}, {y: 0, duration: .5});
  }

}
