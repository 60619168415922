import Plyr from 'plyr';
import Hls from 'hls.js';

export default class Video {

  constructor() {

    //Audio
    Array.from(document.querySelectorAll('.audio-player')).map(p => new Plyr(p, {
      controls: ['play-large', 'play', 'progress', 'current-time'],
    }));

    document.querySelectorAll('.js-player-audio-container').forEach(function (jsp, index){
      jsp.addEventListener('click', function(){

        if (!jsp.getAttribute('play')){
          console.log('play');

          gtag('event', 'play', {
            'event_category': 'audio',
            'event_label': document.title
          });

        }
        jsp.setAttribute('play', 'true');
      });

    });

    document.querySelectorAll('.audio-player').forEach(function (p, index){
      let player = new Plyr(p, {
        controls: ['play-large', 'play', 'progress', 'current-time'],
      });
    });

    // Vimeo players
    document.querySelectorAll('.vimeo-player').forEach(function (p, index){
      let player = new Plyr(p, {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions',  'airplay', 'fullscreen'],
        fullscreen:{ enabled: true, fallback: true, iosNative: true, container: null }
      });
      player.on('play', function(){
        if (!p.getAttribute('play')){

          gtag('event', 'play', {
            'event_category': 'video',
            'event_label': document.title
        });

        }
        p.setAttribute('play', 'true');
      });
    });



    // Alteox players
    document.querySelectorAll('.alteox-player').forEach(function (video, index) {
      if (Hls.isSupported()) {
        let player = new Plyr(video, {
          controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'airplay', 'fullscreen'],
          fullscreen:{ enabled: true, fallback: true, iosNative: true, container: null }
        });
        var hls = new Hls();
        hls.loadSource(video.dataset.playback_url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED,function() {
          //video.play();
        });
        Plyr.setup(video,{
          controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'airplay', 'fullscreen']
        });

        player.on('play', function(){
          if (!video.getAttribute('play')){

            gtag('event', 'play', {
              'event_category': 'video',
              'event_label': document.title
            });

          }
          video.setAttribute('play', 'true');
        });

      }else{
          video.src = video.dataset.playback_url;
      }
    });

    // Vimeo modal
    if(document.getElementById('vimeo-player-modal')) {
      window.playerModal = new Plyr(document.getElementById('vimeo-player-modal'), {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions',  'airplay', 'fullscreen'],
        fullscreen:{ enabled: true, fallback: true, iosNative: true, container: null },
        autoplay: true
      });

      window.playerModal.on('play', function(){
        if (!document.getElementById('vimeo-player-modal').getAttribute('play')){

          gtag('event', 'play', {
            'event_category': 'video',
            'event_label': document.title
          });

        }
        document.getElementById('vimeo-player-modal').setAttribute('play', 'true');
      });



    }

    // Alteox Modal
    if(document.getElementById('alteox-player-modal')) {
      if (Hls.isSupported()) {
        window.playerModal = new Plyr(video, {
          controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'airplay', 'fullscreen'],
          fullscreen:{ enabled: true, fallback: true, iosNative: true, container: null },
          autoplay: true
        });
        var hls = new Hls();
        hls.loadSource(video.dataset.playback_url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED,function() {
          //video.play();
        });
        Plyr.setup(video,{
          controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'airplay', 'fullscreen']
        });

        window.playerModal.on('play', function(){
          if (!document.getElementById('alteox-player-modal').getAttribute('play')){

            gtag('event', 'play', {
              'event_category': 'video',
              'event_label': document.title
            });

          }
          document.getElementById('alteox-player-modal').setAttribute('play', 'true');
        });

      }else{
          video.src = video.dataset.playback_url;
      }
    }

  }

}
