import Highway from '@dogstudio/highway';
import BlockAppear from "../../blockappear";
import TextAppear from "../../textappear";
import { gsap } from "gsap";
import WelcomeLoading from "../../welcome-loading";

export default class AboutRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    BlockAppear.init();
    TextAppear.init();
  }
  onLeave() {
  }
  onEnterCompleted() {
    if (WelcomeLoading.loading){
      setTimeout(function(){
        this.onEnterCompleted();
      }.bind(this), 100);
      return null;
    }
    BlockAppear.observe();
    TextAppear.observe();
  }
  onLeaveCompleted() {
  }
}
