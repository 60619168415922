import Highway from '@dogstudio/highway';
import Clipboard from "../../clipboard";
import Video from "../../video";
import BlockAppear from "../../blockappear";
import TextAppear from "../../textappear";
import { gsap } from "gsap";
import Swiper from "swiper";
import WelcomeLoading from "../../welcome-loading";

export default class ArticleRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
      const carousel = document.querySelector('.swiper-container');
      if (carousel) {
        var mySwiper = new Swiper ('.swiper-container', {
          slidesPerView: 1,
          spaceBetween: 32,
          loop: true,
          lazy: { loadPrevNext: true },
          centeredSlides: true,
          autoHeight: true,
          breakpoints: {
            640: {
              slidesPerView: "auto"
            }
          },
          on: {
            breakpoint: function () {
              setTimeout(function(){
                this.update();
              }.bind(this), 1000);
            },
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        });
      }

      BlockAppear.init();
      TextAppear.init();
    }
    onLeave() {
    }
    onEnterCompleted() {
      if (WelcomeLoading.loading){
        setTimeout(function(){
          this.onEnterCompleted();
        }.bind(this), 100);
        return null;
      }
      new Video();
      new Clipboard();
      BlockAppear.observe();
      TextAppear.observe();
    }
    onLeaveCompleted() {
    }
}
