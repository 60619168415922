import { gsap } from "gsap";
import lottie from 'lottie-web';
import animationData from './../../img/logo/logo.json';

export default class WelcomeLoading {

  constructor() {

    WelcomeLoading.lottieAnimation = lottie.loadAnimation({
      container: document.getElementById('js-loader-logo'),
      renderer: 'svg',
      autoplay: false,
      loop: false,
      animationData
    });

    setTimeout(function(){
      WelcomeLoading.show();
    }, 1000);

    WelcomeLoading.loading = true;

  }

  static show(){
    WelcomeLoading.lottieAnimation.play();
  }

  static hide() {

    if (document.getElementById('js-welcome-loading')){

      setTimeout(function(){
        WelcomeLoading.loading = false;
        setTimeout(function(){
          window.scroll.update();
        }, 100);
      }, 650);

      gsap.timeline()
          .set(document.querySelectorAll('.js-loading-content'), {y: 200}, 0)
          .to(document.querySelectorAll('.js-welcome-loading-container'), {
            height: '0',
            ease: "kuk",
            duration:1.5
          }, 0)
          .to(document.getElementById('js-loader-logo'), {
            scale: .8,
            ease: "kuk",
            duration:1.5
          }, 0)
          .to(document.querySelectorAll('.js-loading-content'), {
            y: 0,
            ease: "kuk",
            duration:1.5
          }, 0);
      }



  }

}
