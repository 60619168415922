import { gsap } from "gsap";

export default class BlockAppear {
  static init(){

    gsap.timeline()
    .set(document.querySelectorAll('[data-router-view] .appear-block'), {opacity:0, y:30}, 0);

  }

  static observe(){

    if ("IntersectionObserver" in window) {

      IntersectionObserver.prototype.POLL_INTERVAL = 100; // Time in milliseconds

      // Appear block
      let lazyBlockObserver = new IntersectionObserver(function (entries, observer) {
        let blocks = [];
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            if(!entry.target.dataset.played){
              entry.target.dataset.played = true;
              blocks = blocks.concat(entry.target);
            }
          }
        });
        BlockAppear.appear(blocks);
      });
      document.querySelectorAll('[data-router-view] .appear-block').forEach(function(block) {
        lazyBlockObserver.observe(block);
      });

    }

  }

  static appear(element = 0) {
    gsap.to( element, {
      opacity:1,
      y:0,
      ease: "expo.out",
      duration:1,
      delay:.25,
      stagger:{
        amount:.2,
        from:0
      }
    });
  }

}
