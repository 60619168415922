//Tailwind
import "./scss/app.scss";

// Plugins
import "alpinejs";
import Highway from "@dogstudio/highway";
import AdminTabs from "./js/adminTabs";
import CookieAgreed from "./js/cookieAgreed";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import CustomEase from "./js/customease";
import WelcomeLoading from "./js/welcome-loading";
import LocomotiveScroll from "locomotive-scroll";
import ScrollIndicator from "./js/scrollindicator";
import Video from "./js/video";

//Highway Renderers
import HomeRenderer from "./js/highway/renderer/home-renderer";
import ArticleRenderer from "./js/highway/renderer/article-renderer";
import AboutRenderer from "./js/highway/renderer/about-renderer";
import SearchRenderer from "./js/highway/renderer/search-renderer";

//Highway Transitions
import Fade from "./js/highway/transition/fade";
import ToArticle from "./js/highway/transition/to-article";

// Misc
import Alpine from "./js/alpine";
import Plyr from "plyr";

/***
 * Init & configure plugins
 */

// Scroll
window.scroll = new LocomotiveScroll({
  el: document.querySelector("[data-scroll-container]"),
  smooth: true,
});

window.scroll.on("scroll", (e) => {
  if (e.scroll.y > 200) {
    gsap.to(document.getElementById("header-shadow"), {
      opacity: 1,
      duration: 1,
    });
  } else {
    gsap.to(document.getElementById("header-shadow"), {
      opacity: 0,
      duration: 1,
    });
  }
});

// ScrollIndicator
new ScrollIndicator();

// Admin bar
new AdminTabs();

// GDPR
document.addEventListener("consent-given", function () {
  console.log("consent given");
  //Enable matomo or google analytics here ?
});
new CookieAgreed();

// Register new ease in GSAP
gsap.registerPlugin(ScrollToPlugin, CustomEase);
CustomEase.create(
  "kuk",
  "M0,0 C0,0 0.10246,0.00078 0.16596,0.00696 0.21598,0.01182 0.24756,0.01622 0.29498,0.02868 0.33471,0.03913 0.36212,0.04886 0.39736,0.06779 0.42825,0.08439 0.45003,0.10042 0.47445,0.12511 0.499,0.14993 0.51442,0.17198 0.53127,0.20319 0.54962,0.23717 0.55883,0.26299 0.5695,0.30113 0.58218,0.34647 0.5871,0.37584 0.59301,0.42388 0.60486,0.52017 0.60238,0.57985 0.61531,0.67343 0.62003,0.7076 0.62782,0.72912 0.63966,0.76162 0.64982,0.78952 0.65769,0.80799 0.67329,0.83263 0.6916,0.86155 0.70616,0.88182 0.73044,0.90498 0.75431,0.92775 0.77489,0.94249 0.80426,0.95757 0.83547,0.9736 0.86033,0.98201 0.89511,0.98955 0.93318,0.9978 1,1 1,1 "
);

// Welcome loader
if (!document.getElementById("admin-tools")) {
  new WelcomeLoading();
}

/***
 * Highway
 */
// Call Highway.Core once.
// Store it in a variable to use events
const H = new Highway.Core({
  renderers: {
    default: HomeRenderer,
    homepage: HomeRenderer,
    article: ArticleRenderer,
    about: AboutRenderer,
    search: SearchRenderer,
  },
  transitions: {
    default: Fade,
    contextual: {
      toArticle: ToArticle,
    },
  },
});

// Listen Events
H.on("NAVIGATE_IN", ({ to, trigger, location }) => {});

H.on("NAVIGATE_OUT", ({ to, trigger, location }) => {
  if (document.getElementById("admin-tools")) {
    window.location.reload();
  }
  var searchopenevent = new CustomEvent("searchopenevent", {
    detail: { isSearchOpen: false },
  });
  window.dispatchEvent(searchopenevent);
});

H.on("NAVIGATE_END", ({ to }) => {
  window.scroll.update();
  // Analytics
  if (typeof gtag !== "undefined") {
    // eslint-disable-next-line
    gtag("js", new Date());
    gtag("config", "UA-168668759-1", {
      page_path: location.pathname,
      page_title: to.page.title,
      page_location: location.href,
      anonymize_ip: !0,
    });
  }
});

H.detach(
  document.getElementById("admin-tools")
    ? document.getElementById("admin-tools").querySelectorAll("a")
    : []
);

window.addEventListener("load", () => {
  if (!document.getElementById("admin-tools")) {
    WelcomeLoading.lottieAnimation.onComplete = function () {
      WelcomeLoading.hide();
    };
  } else {
    setTimeout(function () {
      window.scroll.update();
    }, 100);
  }

  document.getElementById("admin-tools")
    ? (WelcomeLoading.loading = false)
    : null;

  if (document.getElementById("vimeo-player-modal")) {
    window.playerModal = new Plyr(
      document.getElementById("vimeo-player-modal"),
      {
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "mute",
          "volume",
          "captions",
          "airplay",
          "fullscreen",
        ],
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: null,
        },
        autoplay: true,
      }
    );

    window.playerModal.on("play", function () {
      if (!document.getElementById("vimeo-player-modal").getAttribute("play")) {
        gtag("event", "play", {
          event_category: "video",
          event_label: document.title,
        });
      }
      document
        .getElementById("vimeo-player-modal")
        .setAttribute("play", "true");
    });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  document.querySelector("[data-drupal-messages-fallback]").remove();
});
